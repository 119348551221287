import { render, staticRenderFns } from "./form.vue?vue&type=template&id=809ed380&lang=pug&"
import script from "./form.vue?vue&type=script&lang=coffee&"
export * from "./form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CommonNotifyFields from '@/components/common/notify_fields.vue'
import PollCommonClosingAtField from '@/components/poll/common/closing_at_field.vue'
import PollCommonFormFields from '@/components/poll/common/form_fields.vue'
import PollCommonFormOptionsField from '@/components/poll/common/form_options_field.vue'
import PollCommonSettings from '@/components/poll/common/settings.vue'
import PollCommonWipField from '@/components/poll/common/wip_field.vue'
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {CommonNotifyFields,PollCommonClosingAtField,PollCommonFormFields,PollCommonFormOptionsField,PollCommonSettings,PollCommonWipField,VSubheader,VTextField,ValidationErrors})
