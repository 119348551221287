
import { compact, snakeCase, kebabCase } from 'lodash'
import { fieldFromTemplate } from '@/shared/helpers/poll'

export default
  props:
    poll: Object
  data: ->
    currentHideResults: @poll.hideResults
    hideResultsItems: [
      { text: @$t('common.off'), value: 'off' }
      { text: @$t('poll_common_card.until_vote'), value: 'until_vote' }
      { text: @$t('poll_common_card.until_poll_type_closed', pollType: @poll.translatedPollType()), value: 'until_closed' }
    ]

  methods:
    settingDisabled: (setting) ->
      !@poll.closingAt || (!@poll.isNew() && setting == 'anonymous')
    snakify: (setting) -> snakeCase setting
    kebabify: (setting) -> kebabCase setting

  computed:
    allowAnonymous: -> !fieldFromTemplate(@poll.pollType, 'prevent_anonymous')
    settings: ->
      compact [
        ('multipleChoice'         if @poll.pollType == 'poll'),
        ('shuffleOptions'         if ['poll', 'score', 'ranked_choice', 'dot_vote'].includes(@poll.pollType)),
        ('canRespondMaybe'        if @poll.pollType == 'meeting'),
        ('anonymous'              if @allowAnonymous),
        ('voterCanAddOptions'     if fieldFromTemplate(@poll.pollType, 'can_add_options') && @poll.pollType != 'proposal'),
        ('allowLongReason')
      ]
